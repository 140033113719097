.searchBar {
  position: relative;
  width: 200px;

  .searchBar__input {
    padding-left: 35px;
  }

  .searchBar__icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #888;
  }
}
