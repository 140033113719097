.card {
    width: 18rem;

    .card__image {
        object-fit: cover;
        height: 180px;
        position: relative;
    }

    .card__title {
        font-size: 1rem;
    }

    .card__subtitle {
        display: flex;
        justify-content: space-between;
    }

    .card__footer {
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: auto;
        padding-bottom: 0;
    }
}