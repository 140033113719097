.dropdown {
  &__menu {
    padding-left: 9px;
    padding-right: 9px;
    width: max-content;

    &__groupButton {
      width: 100%;
    }
  }
}
