.modal__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 75vh;
  overflow: scroll;

  .App__display {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;

    &__upload__button {
      width: 36em;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;

      svg {
        font-size: 25px;
      }
    }
  }
}

.spinner {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
