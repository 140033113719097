.cardimage {
  width: 36rem;
  display: flex;
  flex-direction: row;

  &__image {
    border-radius: 6px 0 0 6px;
    max-width: 16rem;
  }

  &__body {
    padding: 8px;
    width: 45em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    &__upper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      &__field {
        width: 3em;
        text-align: center;
      }

      &__drag {
        transform: rotate(90deg);
        font-size: 1.5em;
      }

      &__button {
        svg {
          font-size: 1.5em;
        }
      }
    }
  }
}
