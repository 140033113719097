.documents {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5rem;
  padding: 8rem 5rem;
  min-height: 100vh;

  &__bars {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__table {
    th {
      text-align: center;
    }

    &__cell {
      opacity: 0.8;
      vertical-align: middle;
      text-align: center;

      &__name {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        padding: 10px 20px !important;
        text-align: unset;

        &Container {
          display: flex;
          flex-direction: column;
          width: 100%;

          &__documentName {
            opacity: 0.9;
            font-size: 1.1rem;
          }

          &__date {
            opacity: 0.6;
            font-size: 0.85rem;
          }
        }
      }

      &__actions {
        button {
          margin: 0 5px;
        }
      }
    }
  }

  &__quill {
    .ql-container {
      border: 1px solid #ccc; /* Bordure similaire à celle d'un document */
      min-height: 29.7cm; /* Hauteur d'un A4 */
      max-height: 29.7cm;
      width: 21cm; /* Largeur d'un A4 */
      margin: 2rem auto; /* Centrer la zone d'édition */
      background-color: white; /* Fond blanc comme un papier */
      padding: 2cm 1.5cm; /* Marges autour du contenu, similaire à un document Word */
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Ombre légère pour un effet de document */
      font-size: 16px; /* Taille de texte agréable pour l'édition */
      line-height: 1.5; /* Espacement des lignes */
      overflow: auto; /* Gérer le débordement si le contenu dépasse la hauteur définie */
    }

    /* Styles supplémentaires pour le texte */
    .ql-editor {
      height: auto; /* L'éditeur prend toute la hauteur en fonction du contenu */
      overflow: hidden; /* Empêche le défilement interne */
      font-family: 'Arial', sans-serif; /* Choisir une police courante pour les documents */
    }

    /* Styles pour le texte sélectionné ou surligné */
    .ql-editor::selection {
      background-color: rgba(
        0,
        120,
        215,
        0.3
      ); /* Couleur de surlignage lors de la sélection du texte */
    }

    /* Alignement des paragraphes et des titres */
    .ql-align-center {
      text-align: center;
    }
    .ql-align-right {
      text-align: right;
    }
  }
}
