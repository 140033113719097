.transactionCommercial {
  background-color: #ffffff;
  flex: 1;
  margin: 20px 20px 20px 10px;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.5);

  &__core {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__info {
      display: flex;

      &__label {
        opacity: 0.8;
        width: 50%;
      }

      &__date {
        height: 30px;
        width: 50%;
      }
    }

    &__infoGroup {
      display: flex;
      gap: 15px;
      width: 50%;

      &__input {
        width: 50%;

        span {
          min-width: unset;
          width: unset;
        }
      }
    }
  }
}
