.boat {
  background-color: #efefef;
  min-height: 100vh;
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  // Carousel
  .boat__carousel {
    margin-top: 15px;
    position: relative;
  }
  // All accordions
  .boat__accordion {
    width: 1000px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 15px;
    margin-bottom: 30px;
    // Position of arrow of accordion
    .accordion-button {
      align-items: baseline;
    }
    // First accordion with the main informations
    .accordion__specifications {
      width: 100%;

      .specifications__info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .info__strong {
          font-size: 1.75em;
          font-weight: 600;
        }

        .info__status {
          font-size: 1.25em;
          font-weight: 600;
        }
      }

      .specifications__measures {
        display: flex;
        flex-direction: column;
      }

      .specifications__details {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    // Header of accordion
    .accordion__header {
      width: 100%;

      .header__container {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .sails__container {
        flex-direction: row;
        flex-wrap: wrap;

        .container__item {
          width: 50%;

          .item__type {
            font-weight: 700;
          }
        }
      }
    }
    // Title
    .accordion__title {
      font-size: 1.75em;
      font-weight: 600;
    }
    // Subtitle
    .accordion__subtitle {
      font-size: 1.25em;
      font-weight: 600;
      margin: 10px 0;
    }

    .accordion__update {
      .update__canDelete {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn__container {
          display: flex;
          flex-direction: row;

          .canDelete__button {
            height: 40px;
            display: flex;
            align-items: center;
            margin-left: 5px;
          }
        }
      }

      .update__label {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        &__currencies {
          width: 100%;

          button {
            width: 100%;
          }

          &__menu {
            max-height: 300px; // Limite la hauteur du menu
            overflow-y: auto; // Active le scroll vertical à l'intérieur du menu
            width: 100%; // S'assure que le menu prend toute la largeur du dropdown
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Un léger ombrage pour la lisibilité
            z-index: 1000;
            font-size: 15px;

            &__flag {
              margin-right: 8px;
            }
          }
        }
      }

      .update__addButton {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 15px;
        height: 30px;
      }

      .category__container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;

        .container__checkbox {
          flex: 3;
        }

        .container__description {
          flex: 20;
        }
      }
    }

    .transaction__people {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .people__person {
        width: 48%;

        .person__category {
          font-weight: 700;
        }

        .person__container {
          display: flex;
          flex-direction: column;
          gap: 7px;
        }

        .person__addButton {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          margin-top: 15px;
          height: 30px;
          width: 100%;
        }
      }
    }

    .transactionInfo__container {
      display: flex;
      flex-wrap: wrap;
      row-gap: 7px;
      column-gap: 4%;

      .container__item {
        display: flex;
        flex: 1 1 45%;
        box-sizing: border-box;

        .item__label {
          opacity: 0.8;
          display: inline-block;
          width: 50%;
        }

        .item__value {
          display: inline-block;
          width: 50%;
        }
      }
    }
  }

  .buttons__container {
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: flex;
    gap: 10px;
    z-index: 2;

    .container__toast {
      margin: 50px 0;
      color: #efefef;
    }

    .container__button {
      font-size: 1.5em;
      display: flex;
    }

    .button__disabled:hover {
      cursor: not-allowed;
    }
  }
}
