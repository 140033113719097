.dashboard {
  &__cardNumber {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);

    &__icon {
      /* Cercle coloré pour l’icône */
      // background-color: #e8d4fa;
      // color: #8b4ecf;
      border-radius: 50%;
      padding: 12px;
      font-size: 24px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__content {
      display: flex;
      flex-direction: column;
      margin-right: auto;

      &__number {
        font-size: 1.8rem;
        font-weight: 600;
        color: #333;
      }

      &__title {
        font-size: 0.9rem;
        color: #888;
        margin-bottom: 8px;
      }
    }
  }
}
