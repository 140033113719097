.people {
  background-color: #efefef;
  min-height: 100vh;
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .people__top {
    width: 75%;
    margin: 20px 0;
    padding: 20px 15px;
    background-color: whitesmoke;
    border-radius: 15px;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);

    .top__group {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }

  .people__warn {
    background-color: #ffc107;
  }

  .people__error {
    background-color: #dc3545;
    color: white;
  }

  .people__notification {
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    .people__notification__text {
      display: none;
    }
  }

  .people__line {
    width: 70%;
  }

  .people__results {
    width: 85%;

    a {
      text-decoration: none;
      color: black;
    }
  }

  .people_nofound {
    font-size: 2.5em;
  }
}
