.createBoat {
  background-color: #efefef;
  min-height: 100vh;
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &__form {
    width: 75%;
    margin: 20px 0;
    padding: 20px 15px;
    background-color: whitesmoke;
    border-radius: 15px;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
  }

  .buttons__container {
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: flex;
    gap: 10px;
    z-index: 2;

    .container__toast {
      margin: 50px 0;
      color: #efefef;
    }

    .container__button {
      font-size: 1.5em;
      display: flex;
    }

    .button__disabled:hover {
      cursor: not-allowed;
    }
  }
}
