.dashboard {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5rem;
  min-height: 100vh;

  &__cardsContainer {
    width: 100%;
    padding: 0 1rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
