.transactionCore {
  background-color: #ffffff;
  flex: 2;
  margin: 20px 10px 20px 20px;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.5);

  &__loading {
    height: 7rem;
  }

  &__foreTitle {
    font-size: small;
    opacity: 0.8;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 10px;

    &__edit {
      width: 20%;
    }
  }

  &__subtitle {
    font-size: small;
    opacity: 0.8;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  &__subtitleDisplay {
    top: -10px;
  }

  &__subtitleEditing {
    top: 0;

    &__input {
      width: 20%;
    }
  }

  &__transitionTitle {
    margin-top: 0.5rem;
  }

  &__boat {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__text {
        display: flex;
        align-items: baseline;
        gap: 7px;
      }

      &__button {
        font-size: small;
        display: flex;
        align-items: center;
        gap: 2px;
      }
    }

    &__core {
      display: flex;
      flex-direction: column;

      &__info {
        display: flex;
        justify-content: space-between;

        span {
          width: 50%;
        }

        &__label {
          opacity: 0.8;
        }
      }
    }
  }

  &__addBuyer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }

  &__toastContainer {
    margin: 30px;
    color: #efefef;
  }

  &__editGroup {
    display: flex;
    justify-content: space-between;

    &__editButton {
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }
}
