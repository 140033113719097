.transaction {
  background-color: #efefef;
  margin-left: 5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 1vw;
  min-height: 100vh;

  &__rightSide {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .secondBloc {
    background-color: cornflowerblue;
    flex: 1;
    margin: 20px 20px 20px 10px;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 15px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.5);
  }
}
