// Carousel spinner
.carouselSpinner {
  width: 540px;
  height: 540px;
}

.updateContainer {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 2;
  display: flex;
  gap: 15px;

  &__button {
    font-size: 1.3em;
    display: flex;
    align-items: center;
  }
}

.carousel {
  width: 960px;
  height: 540px;
  background-color: #cacaca;
  border-radius: 15px;

  img {
    height: 540px;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__placeholder {
    width: inherit;
  }

  &-inner {
    border-radius: 15px;
  }
}
