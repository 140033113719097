.transactionCore {
  &__loading {
    height: 6rem;
  }

  &__person {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__text {
        display: flex;
        align-items: baseline;
        gap: 7px;
      }

      &__button {
        font-size: small;
        display: flex;
        align-items: center;
        gap: 2px;
      }
    }

    &__core {
      display: flex;
      flex-direction: column;

      &__info {
        display: flex;
        justify-content: space-between;

        span {
          width: 50%;
        }

        &__label {
          opacity: 0.8;
        }
      }
    }
  }
}
