.admin {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5rem;
  padding: 0 5rem;
  min-height: 100vh;

  &__search {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    &__title {
      font-weight: 500;
      font-size: medium;
    }
  }

  &__utils {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-bottom: 1rem;
    width: 100%;

    .searchBar {
      width: 100%;
    }

    &__buttons {
      width: 90%;
      min-width: 145px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
  }

  &__table {
    th {
      text-align: center;
    }

    &__cell {
      opacity: 0.8;
      vertical-align: middle;
      text-align: center;

      &__image {
        width: 64px;
        height: 64px;
        object-fit: cover;
      }

      &__name {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        padding: 10px 20px !important;
        text-align: unset;

        &Container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        &__username {
          opacity: 0.9;
          font-size: 1.1rem;
        }

        &__email {
          opacity: 0.6;
          font-size: 0.85rem;
        }
      }

      &__actions {
        button {
          margin: 2px;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px;
  }
}
