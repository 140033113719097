.sidebar {
    background-color: #95d6f2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 5rem;
    height: 100vh;
    position: fixed;
    padding: 15px 0;
    gap: 5px;
    z-index: 2;
    overflow: hidden;
    transition: 
        width 0.2s;

    .sidebar__link {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 600;
        gap: 15px;
        border-radius: 10px;
        margin: 0 10px;
        padding: 0 15px;
        height: 40px;
        text-decoration: none;

        svg {
            font-size: larger;
            position: absolute;
            margin-left: 5px;
        }

        .sidebar__link__label {
            margin-left: 2rem;
            opacity: 0;
            transition: 
                opacity 0.2s;
            transition-delay: 0.1s;
        }
    }

    .sidebar__hr {
        margin: 1rem 10px;
        height: 5px;
        background-color: #0d6efd;
        border: none;
        border-radius: 5px;
        opacity: 1;
    }

    .logoutButton {
        background-color: transparent;
        border: none;
        color: #0d6efd;
        width: 220px;
    }

    .active {
        background-color: #8993ad;
        color: #efefef;
    }

    .sidebar__link:hover {
        background-color: #13275c;
        color: #efefef;
        cursor: pointer;
    }

    .sidebar__wrapper {
        padding: 0;
        margin: 0;
    }

    .sidebar__bottom {
        margin-top: auto;
    }
}

.sidebar:hover {
    width: 15rem;

    .sidebar__link {
        
        .sidebar__link__label {
            opacity: 1;
        }
    }
}