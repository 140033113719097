.modal {
  &__body {
    display: block;
    overflow: unset;
    max-height: 75vh;
    overflow-y: scroll;

    &__form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      justify-content: space-between;

      &__group {
        display: flex;
        gap: 0.5rem;

        &__dropdown {
          width: 50%;

          button {
            width: 100%;
          }

          &__menu {
            width: 100%;
          }
        }

        &__dropdownCurrency {
          width: 100%;

          button {
            width: 100%;
          }

          &__menuCurrency {
            max-height: 300px; // Limite la hauteur du menu
            overflow-y: auto; // Active le scroll vertical à l'intérieur du menu
            width: 100%; // S'assure que le menu prend toute la largeur du dropdown
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Un léger ombrage pour la lisibilité
            z-index: 1000;
            font-size: 15px;

            &__flag {
              margin-right: 8px;
            }
          }
        }

        &__password {
          position: relative;
          width: 100%;

          &__input {
            padding-right: 30px;
          }

          &__button {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;

            &__icon {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              pointer-events: none;
              color: #888;
            }
          }

          &__button:hover,
          &__button:active {
            color: unset !important;
            background: none !important;
            border: none !important;
          }
        }

        &__imageButton {
          width: 156px;
          height: 156px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          background: none;
          border: none;

          &:hover,
          &:active {
            color: unset !important;
            background: none !important;
            border: none !important;
          }

          &__image {
            width: 128px;
            height: 128px;
            object-fit: cover;
          }

          &--hover {
            display: none;
            position: absolute;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            opacity: 0.8;
            color: rgb(32, 32, 32);
          }

          &:hover &--hover {
            display: flex;
          }
        }
      }
    }

    &__permissions {
      display: flex;
      justify-content: space-between;

      &__title {
        font-size: 1.3rem;
        font-weight: 500;
        opacity: 0.7;
      }

      &__group {
        &__list {
          list-style: none;
          padding-left: 1rem;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 1rem;
          justify-content: space-between;

          &__item {
            flex: 1 1 45%;
            max-width: 45%;
          }
        }
      }
    }
  }
}
