.editGroup {
  position: relative;

  &__valideCancelGroup {
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: absolute;
    top: 0;
  }
}

.editButton {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 2px;
  min-width: 77px;
  min-height: 38px;

  &-onclick {
    display: flex;
  }
}
