.customDropdown {
  width: 100%;

  &__button {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__menu {
    max-height: 200px;
    overflow-y: auto;
  }
}
