.boats {
  margin-left: 5rem;
  background-color: #efefef;
  min-height: 100vh;

  .boats__spinner {
    width: 7rem;
    height: 7rem;
    margin: auto;
  }

  .boats__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    position: absolute;
    top: 250px;
    background-color: #efefef;
    padding-bottom: 15px;
  }
}
