.person {
  background-color: #efefef;
  min-height: 100vh;
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .person__accordion {
    width: 75%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 15px;
    margin-bottom: 30px;

    .accordion__title {
      font-size: 1.75em;
      font-weight: 600;
    }

    .accordion__update {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }

  &__boats {
    background-color: #ffffff;
    flex: 2;
    margin: 20px 10px 20px 20px;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 15px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.5);
    width: 75%;

    &__title {
      font-size: 1.75em;
      font-weight: 600;
    }

    &__addingBoat {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 25px;
    }
  }

  .validForm {
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: flex;
    gap: 10px;
    z-index: 2;

    .validForm__toast {
      margin: 50px 0;
      color: #efefef;
    }
  }
}
