.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 1rem 0;
  position: fixed;
  top: 10px;
  z-index: 5;
  background-color: white;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);

  .form__up {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .form__buttonWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .form__buttonWrapper__group {
        height: 38px;

        .form__buttonWrapper_button {
          background-color: #95d6f2;
          color: #0d6efd;
          font-weight: 600;
          border: none;
        }

        .form__buttonWrapper_button:hover {
          background-color: #13275c;
          color: #efefef;
        }

        .form__buttonWrapper__dropdown,
        .form__buttonWrapper__dropdownEnd {
          background-color: #95d6f2;
          display: flex;
          flex-direction: column;

          button {
            border: none;
            min-height: 100%;
          }
        }

        .form__buttonWrapper__dropdown:hover,
        .form__buttonWrapper__dropdownEnd:hover {
          background-color: #13275c;
          color: #efefef;
        }

        .form__buttonWrapper__dropdownEnd {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .active {
          background-color: #8993ad;
          color: #efefef;
        }
      }

      .form__buttonWrapper__input {
        font-weight: 600;
      }
    }

    .form_inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .form_inputWrapper_input {
        display: flex;
        flex-direction: row;
        gap: 10px;

        input,
        span {
          font-weight: 600;
        }

        span {
          width: 40px;
        }
      }
    }
  }

  .form__down {
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 20px;
    justify-content: center;

    .button__badge {
      background-color: #8993ad;
      border: none;
    }

    .active {
      background-color: #198754;
    }
  }

  // .form_dropdownWrapper {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;

  //     button {
  //         background-color: #13275c;
  //         border: none;
  //         font-weight: 600;
  //         width: 260px;
  //     }

  //     .dropdown-menu {
  //         width: 260px;

  //         ul {
  //             padding-left: 5px;
  //         }
  //     }

  //     .dropdown__option {
  //         padding-left: 2em;
  //         font-weight: 600;

  //         .form-check-input:checked {
  //             background-color: #13275c;
  //         }
  //     }

  //     .buttonGroup {
  //         width: 100%;
  //         .buttonGroup__button {
  //             background-color: transparent;
  //             color: #13275c;
  //             width: auto;
  //         }
  //     }
  // }

  // .form_switch {
  //     font-weight: 600;

  //     input:checked {
  //         background-color: #13275c;
  //     }
  // }
}
