.xml {
  background-color: #efefef;
  min-height: 100vh;
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__form__group {
    display: flex;
    gap: 1rem;
    margin: 1rem;
  }

  &__toast {
    margin: 2rem;
    color: #efefef;
  }
}
