.transactions {
  background-color: #efefef;
  min-height: 100vh;
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .transactions__filters {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 1rem;

    .filters__modelSearch {
      position: relative;
      width: 200px;

      .modelSearch__input {
        padding-left: 30px;
      }

      .modelSearch__icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        color: #888;
      }
    }
  }

  .transactions__table {
    width: 85%;

    .table__body {
      .body__row {
        .row__cell {
          padding: 1rem 0.5rem;
        }
      }

      a {
        text-decoration: none;
        color: black;
      }
    }
  }
}
